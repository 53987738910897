var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'objects-overlay',
    'fixed',
    'top-5',
    'left-10',
    'bottom-5',
    'bg-overlayColor',
    'z-40',
    'ml-8',
    'rounded-xl',
    _vm.visible ? 'block' : 'hidden'
  ]})
}
var staticRenderFns = []

export { render, staticRenderFns }